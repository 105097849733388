<template>
    <div>
        <sub-nav :nav="topnav" @change="changetop" :defaultActive="defaultFreq"></sub-nav>
        <div class="padding-lr-20 padding-top-20">
            <div class="column">
        <div class="flex-row justify-between ">
            <div class="flex-row align-items-center">
                <div class="margin-right-30">因子管理</div>
                <div class="margin-right-30"><el-input v-model="keyword" placeholder="请输入内容" @change="searchData"  suffix-icon="el-icon-search" size="mini"></el-input></div>
                <div >
                    <el-select v-model="curtype" placeholder="请选择"  size="mini"  @change="changeType">
                        <el-option
                        v-for="item in typeptions"
                        :key="item.value"
                        :label="item.title"
                        :value="item.value"
                       
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            
            <div  class="flex-row align-items-center">
                <div class="margin-right-30">
                    <el-button  size="mini"  @click="addTarget">添加目标</el-button>
                    <el-button  size="mini"  @click="addFactor">添加因子</el-button>
                </div>
                <div class="font-size-12">
                    数量：{{tableData.length}}
                </div>
            </div>
        </div>
        <div class="margin-top-30">
            <el-table
                :data="tableData"
                height="calc(100vh - 235px)"
                v-loading="tableDataloading"
                style="width: 100%">
                <el-table-column
                  
                    label="类型"
                    width="100">
                    <template v-slot="scope">
                        <template v-if="scope.row.isTarget==1">目标</template>
                        <template v-if="scope.row.isTarget==0">因子</template>
                    </template>
                </el-table-column>
                <el-table-column
                   label="名称"
                   >
                   <template v-slot="scope">
                  <el-popover placement="right-start" width="350" trigger="hover">
                    <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">
                        <div><span class="color-sub">数据名称：</span>{{scope.row.child.factorName}}</div>
                        <div><span class="color-sub">数据单位：</span>{{scope.row.child.unit}}</div>
                        <div><span class="color-sub">数据类型：</span>{{scope.row.child.typeName}}</div>
                        <div><span class="color-sub">更新频率：</span>{{scope.row.child.modelFreq}}</div>
                        <div><span class="color-sub">统计日期：</span>{{scope.row.child.startDate}}-{{scope.row.endDate}}</div>
                        <div><span class="color-sub">方法论：</span>{{scope.row.child.func}}</div>
                        <div><span class="color-sub">数据来源：</span>{{scope.row.child.dataSource}}</div>
                    </div>
                    <span slot="reference" class="display-inline-block link">
                     {{scope.row.divisorName}}
                    </span>
                  </el-popover>
                </template>
                </el-table-column>
                <el-table-column
                    prop="unit"
                    label="单位"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="productName"
                    label="关联产品"
                    width="130px"
                   >
                </el-table-column>
                <el-table-column
                    prop="useData"
                    label="使用数据"
                   >
                </el-table-column>
               
                <el-table-column
                    prop="name"
                    label="数据周期"
                   >
                   <template  v-slot="scope">
                        {{scope.row.startDate}} 至 {{scope.row.endDate}}
                   </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                   >
                   <template  v-slot="scope">
                        <span class="link-text margin-right-10"  @click="editFactor(scope.row)">编辑</span>
                        <span class="link-text margin-right-10"  @click="getDivisorChart(scope.row)">查看</span>
                        <span class="link-text" @click="deleteFactor(scope.row)">删除</span>
                   </template>
                </el-table-column>
                </el-table>
        </div>
        <!------------------ 编辑因子弹窗开始 ---------------------->
        <el-dialog
            :title="curFactor.isTarget=='1'?'目标信息编辑':'因子信息编辑'"
            :visible.sync="editFactvisiable"
            width="420px"
            :append-to-body=true
            custom-class="custom-dialog"
            :close-on-click-modal=false
            class="factormangedialog"
           >
            <div>
                <div class="margin-top-15">
                    <el-form ref="editfactorform" :model="curFactor" label-width="73px" size="mini">
                        <el-form-item :label="curFactor.isTarget=='1'?'目标名称':'因子名称'">
                            <el-input v-model="curFactor.divisorName" maxlength="20" show-word-limit></el-input>
                        </el-form-item>
                        <!-- <el-form-item :label="curFactor.isTarget=='1'?'目标单位':'因子单位'">
                            <el-input v-model="curFactor.unit" maxlength="20" show-word-limit></el-input>
                           
                        </el-form-item> -->
                    </el-form>
                </div>
                <div class="flex-row justify-center margin-top-30">
                    <el-button @click="editFactvisiable = false" style="width: 100px;" size="mini">取 消</el-button>
                    <el-button type="primary" @click="saveEdit" style="width: 100px;" size="mini">确 定</el-button>
                </div>
            </div>
        </el-dialog>
        <!------------------ 编辑因子弹窗结束----------------------->
        <!---------------- 添加目标/因子弹窗开始 ------------------->
        <el-dialog
            :title=" curdialogtype==1?'添加目标':'添加因子'"
            :visible.sync="dialogVisible"
            width="60%"
            :append-to-body=true
            custom-class="custom-dialog"
            class="factormangedialog"
            @close="cleardata"
            :destroy-on-close=true
           >
           <div class="border-top padding-top-10">
            <div class="flex-row justify-between ">
                <div class="flex-row align-items-center">
                    <div class="margin-right-30">
                        <el-select v-model="productID" 
                        placeholder="请选择"  
                        size="mini"
                        @change="changeProduct"
                        >
                            <el-option
                            v-for="item in productList"
                            :key="item.productId"
                            :label="item.productName"
                            :value="item.productId">
                            </el-option>
                        </el-select>
                    </div>
                    <div ><el-input v-model="dialogkeyword" placeholder="请输入内容" @change="searchDialog"  suffix-icon="el-icon-search" size="mini"></el-input></div>
                </div>
                
                <div class="font-size-12 color-sub" > 数量：{{dialogtableData.length}}</div>
            </div>
            <div class="margin-top-10" v-if="curdialogtype==0">
                <el-table
                    :data="dialogtableData"
                    style="width: 100%"
                    height="calc(70vh - 150px)"
                    @selection-change="selectionChange"
                    >
                        <el-table-column
                            type="selection"
                            width="55">
                        </el-table-column>
                       
                        <el-table-column label="待选数据">
                        <template v-slot="scope">
                            <el-popover placement="right-start" width="350" trigger="hover">
                                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">
                                    <div><span class="color-sub">数据名称：</span>{{scope.row.child.factorName}}</div>
                                    <div><span class="color-sub">数据单位：</span>{{scope.row.child.unit}}</div>
                                    <div><span class="color-sub">数据类型：</span>{{scope.row.child.typeName}}</div>
                                    <div><span class="color-sub">更新频率：</span>{{scope.row.child.modelFreq}}</div>
                                    <div><span class="color-sub">统计日期：</span>{{scope.row.child.startDate}}-{{scope.row.endDate}}</div>
                                    <div><span class="color-sub">方法论：</span>{{scope.row.child.func}}</div>
                                    <div><span class="color-sub">数据来源：</span>{{scope.row.child.dataSource}}</div>
                                </div>
                                <span slot="reference" class="display-inline-block link">
                                {{scope.row.targetNameAll}}
                                </span>
                            </el-popover>
                        </template>
                        </el-table-column>
                        <el-table-column
                            label="类型"
                            prop="typeName"
                            width="100">
                        </el-table-column>
                        <el-table-column
                                prop="name"
                                label="数据周期"
                            >
                            <template  v-slot="scope">
                                    {{scope.row.startDate}}-{{scope.row.endDate}}
                            </template>
                            </el-table-column>
                    </el-table>
                
            </div>
            <div class="margin-top-10" v-if="curdialogtype==1">
                <el-table
                    :data="dialogtableData"
                    style="width: 100%"
                    height="calc(70vh - 150px)"
                    @selection-change="selectionChange"
                    >
                        <el-table-column
                            type="selection"
                            width="55">
                        </el-table-column>
                        <template  >
                        <el-table-column
                            label="产品"
                            prop="productName"
                            width="120">
                         </el-table-column>
                        </template>
                       
                        <el-table-column label="预测目标">
                        <template v-slot="scope">
                            <el-popover placement="right-start" width="350" trigger="hover">
                                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">
                                    <div><span class="color-sub">数据名称：</span>{{scope.row.child.factorName}}</div>
                                    <div><span class="color-sub">数据单位：</span>{{scope.row.child.unit}}</div>
                                    <div><span class="color-sub">数据类型：</span>{{scope.row.child.typeName}}</div>
                                    <div><span class="color-sub">更新频率：</span>{{scope.row.child.modelFreq}}</div>
                                    <div><span class="color-sub">统计日期：</span>{{scope.row.child.startDate}}-{{scope.row.endDate}}</div>
                                    <div><span class="color-sub">方法论：</span>{{scope.row.child.func}}</div>
                                    <div><span class="color-sub">数据来源：</span>{{scope.row.child.dataSource}}</div>
                                </div>
                                <span slot="reference" class="display-inline-block link">
                                {{scope.row.targetNameAll}}
                                </span>
                            </el-popover>
                        </template>
                        </el-table-column>
                       <el-table-column
                            label="市场"
                            prop="marketAttr"
                           >
                        </el-table-column>
                        <el-table-column
                            label="规格牌号"
                            prop="dataModel"
                           >
                        </el-table-column>
                   
                      
                        <el-table-column
                                prop="name"
                                label="数据周期"
                            >
                            <template  v-slot="scope">
                                    {{scope.row.startDate}}-{{scope.row.endDate}}
                            </template>
                            </el-table-column>
                    </el-table>
                
            </div>
           </div>
            <div class="flex-row justify-center margin-top-20">
                <el-button @click="dialogVisible = false" style="width: 100px;" size="mini">取 消</el-button>
                <el-button type="primary" @click="saveSelected" style="width: 100px;" size="mini">确 定</el-button>
            </div>
            </el-dialog>
        <!----------------- 添加目标/因子弹窗结束 --------------->
    <!---------------- 因子数据图详情弹窗 -------------->
   <el-dialog custom-class="custom-dialog noheadercustom-dialog" append-to-body :close-on-click-modal="false" width="80%" top="15vh" :visible.sync="divisorData.visible">
      <div  v-loading="divisorData.loading">
        <el-tabs v-model="activedivisorTab" >
            <el-tab-pane label="查看因子" name="first">
                <div class="flex-column" style="height: calc(70vh - 180px); overflow-y: auto;">
                    <Chart :option="divisorChart" class="flex-auto"></Chart>
                </div>
            </el-tab-pane>
            <el-tab-pane label="数据详情" name="second">
                <div  style="height: calc(70vh - 180px); overflow-y: auto;">
                    <el-table
                :data="divisorData.info?divisorData.info.dataList:[]"
                style="width: 100%"
                >
                <el-table-column                                                                                         
                    prop="date"
                    label="日期"
                    >
                </el-table-column>
                    <el-table-column 
                        prop="value"
                        align="center"
                        :label="divisorActive.divisorName" >
                    </el-table-column>
                 </el-table>
                </div>
               
            </el-tab-pane>
            
        </el-tabs>
      </div>
    </el-dialog>
    <!-- 因子详情结束 -->
        </div>
        </div>
        
    </div>
  
</template>
<script>
import SubNav from '@/components/SubNav.vue';
import Chart from "@/components/Chart";
export default ({
   name:"factormanage",
   components:{
        SubNav,
        Chart
      },
   data() {
    return{
        keyword:'',
        curtype:'',
        tableDataloading:false,
        typeptions:[{
            title:'全部',
            value:''
         },
         {
            title:'我的预测目标',
            value:1
         },
         {
            title:'我的因子',
            value:0
         }],
        dialogkeyword:'',
        productList:[],
        productID:'',
        tableData:[],
        tableDataall:[],
        dialogtableData:[],
        dialogtableDataall:[],
        dialogVisible:false,
        editFactvisiable:false,
        curFactor:{
            divisorName:'',
            unit:''
        },
        defaultFreq:'week',
        activeFreq:'week',
        topnav:[
                {
                    name:"周度",
                    value:'week'
                },
                {
                    name:"月度",
                    value:'month'
                }
            ],
        selecedList:[],
        curdialogtype:0,//1目标，0因子
        divisorData:{//因子图表详情
            loading:false,
            visible:false,
            info:{
                item:{}
            }
        },
        divisorActive:{},//当前编辑的因子
        activedivisorTab:"first",
    }
   },
   methods:{
    selectionChange(list){
        //弹窗列表选中切换
        this.selecedList=list;
    },
    changeType(){
        //切换类型
        this.$nextTick(()=>{
            this.getfacList()
        })
       
    },
     //获取因子走势图详情
     getDivisorChart(divisor){
            this.divisorActive=divisor
            this.activedivisorTab="first"
            this.divisorData={
                loading:true,
                visible:true,
                info:{
                    item:{}
                }
            }
            this.$instance.get('/befarCustomModel/getHisCustomeDivisor',{
                params: {
                    unit:divisor.unit,
                    modelFreq:this.activeFreq,
                    divisorId:divisor.customDivisorId,
                    startDate:divisor.startDate,
                    endDate:divisor.endDate
                },
            })
            .then(res=>{
                this.divisorData.loading=false;
                if(res.data.code==0){
                this.divisorData.info={
                    item:res.data.info.item,
                    dataList:res.data.info.dataList.map(o=>{
                        return {
                            ...o,
                            value: this.$numFormat(o.value,2,'-')
                        }
                        
                       
                    })
                };
                
                }else{
                this.divisorData.info={}
                }
            })
            .catch(()=> {
                this.divisorData.loading=false;
                this.divisorData.info={}
            }); 
        },
    searchData(val){
        //文本框搜索
        if(val.trim().length>0){
            this.tableData=this.tableDataall.filter(o=>{return o.divisorName.indexOf(val.trim())>=0})
        }else{
            this.tableData=this.tableDataall;
        }
        console.log(val);
    },
    getfacList(){
        this.tableDataloading=true;
        this.tableData=[];
        this.tableDataall=[];
        this.$instance.get('/befarCustomModel/getDataDivisorList',{params:{modelFreq:this.activeFreq,type:this.curtype}}).then(res=>{
           
            this.tableDataall=res.data.info;
            if(this.keyword.trim().length>0){
                this.searchData(this.keyword.trim())
            }else{
                this.tableData=res.data.info;
            }
            this.tableDataloading=false;
        }).catch(()=>{
            this.tableDataloading=false;
        })
    },
    changetop(val){//顶部周期切换
             this.activeFreq=val;
             //切换类型
            this.$nextTick(()=>{
                this.keyword='';
                this.curtype='';
                this.getfacList()
            })
          //   this.$refs.reportinfo.changetop(val);//调用组件中的切换频率
    },
    async getproducList(){
        await  this.$instance.get('/manage/getProductPowerWithCustom',{params:{moduleCode:"shu_ju_guan_li"}}).then(res=>{
                    if(res.data.code==0 && res.data.info){
                        this.productList=res.data.info;
                        
                        //设置默认选中第一个权限产品
                        if(this.productList[0]){
                            this.productID= this.productList[0].productId;
                         //   this.targetId=this.options[0].productType=="default"?this.options[0].targetId:0;
                        }else{
                            this.$alert(`您暂无此模块的产品权限`, '提示', {
                            customClass:"custom-msgbox",
                            confirmButtonText: '确定',
                            showClose:false,
                            type: 'warning'
                            })
                          
                        }
                    }
                })
    },
    deleteFactor(row){
        //删除因子
        if(row.isDelete==0){
            //不可删除
            let temp="";
            row.modelNames.forEach(o=>{
                temp=temp+"<br>"+o
            })
            this.$alert('您的预测方案在使用该因子，若想删除该因子，请先删除相关的预测方案。<br>使用该因子的预测方案：'+temp, '提示', {
                confirmButtonText: '知道了',
                dangerouslyUseHTMLString: true
            });
        }else{
            this.$confirm('确定要删除此因子吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.$instance.get('/befarCustomModel/deleteCustomDivisor',{params:{customDivisorId:row.customDivisorId}}).then(res=>{
                    if(res.data.code==0){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getfacList();
                    }
                })
            
            })
        }
       
    },
    editFactor(row){
        //点击编辑因子
        console.log(row);
        this.curFactor=JSON.parse(JSON.stringify(row))
        this.editFactvisiable=true;
    },
    saveEdit(){//保存编辑内容
        this.$instance.get('/befarCustomModel/editCustomDivisor',{params:{customDivisorId:this.curFactor.customDivisorId,customDivisorName:this.curFactor.divisorName}}).then(res=>{
           if(res.data.code==0){
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
            this.editFactvisiable=false
            this.getfacList();
           }
        })
        
    },
    addTarget(){
        //添加目标
        this.curdialogtype=1;
        this.dialogVisible=true;
        this.getproducList().then(()=>{
            this.getdailogData();
        })
    },
    addFactor(){
        //添加因子
       this.curdialogtype=0;
       this.dialogVisible=true;
       this.getproducList().then(()=>{
        this.getdailogData();
       })
      
       
    },
    changeProduct(){
        //切换弹窗产品
        this.dialogkeyword='';
        this.$nextTick(()=>{
            this.getdailogData();
         })
    },
    getdailogData(){
        this.dialogtableData=[];
        this.dialogtableDataall=[];
        const url=this.curdialogtype==0?'/befarCustomModel/getAddDivisorList':'/befarCustomModel/getAddTargetList'
        this.$instance.get(url,{params:{productId:this.productID,modelFreq:this.activeFreq}}).then(res=>{
            if(res.data.code==0){
                    this.dialogtableData=res.data.info;
                    this.dialogtableDataall=res.data.info;
            }
        })
    },
    cleardata(){
        //console.log('close');
        this.dialogtableData=[];
        this.dialogtableDataall=[];
    },
    searchDialog(val){
        //搜索弹窗内容
        console.log(val);
        if(this.dialogkeyword.trim().length>0){
            this.dialogtableData=this.dialogtableDataall.filter(o=>{return o.targetNameAll.indexOf(this.dialogkeyword.trim())>=0})
        }else{
            this.dialogtableData=this.dialogtableDataall;
        }
    },
    saveSelected(){
        //保存弹窗所选
        this.$nextTick(()=>{
            const templist=this.selecedList.map((item)=>{
                return {
                    ...item,
                    modelFreq:this.activeFreq,
                    productId:this.productID,
                    productName:'',
                    child:{}
                 }
            })
            console.log(templist);
            if(this.selecedList.length>0){
                this.$instance.post('/befarCustomModel/addTarget',templist).then(res=>{
                    //console.log(res);
                    if(res.data.code==0){
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.dialogVisible=false;
                        this.getfacList();
                    }
                })
            }else{
                this.$message({
                    message: '请至少选择一个数据',
                    type: 'warning'
                });
            }
         
        })
      
    }
   },
   mounted(){
        this.getfacList();
   },
   computed:{
  
    divisorChart() {
          let chartData = this.divisorData.info;
          if (chartData.item.name) {
            let opt = JSON.parse(JSON.stringify(this.$defaultChartOption));
            opt.yAxis.name =  chartData.item.unit;
            opt.xAxis.boundaryGap = false;
            opt.xAxis.data = chartData.dataList.map((v) => {
              return v.date;
            });
            // opt.dataZoom.startValue=opt.xAxis.data.length-15
            opt.legend.data =  [{
                name: this.divisorActive.divisorName,
                unit: chartData.item.unit,
            }];
           
            opt.series = {
                name: this.divisorActive.divisorName,
                type: "line",
                connectNulls: true,
                data: chartData.dataList.map((vv) => {
                  return vv.value;
                }),
              };
           
            opt.tooltip.formatter = (params) => {
              let str = `<div>${params[0].axisValue}</div>`;
              params.forEach((val) => {
                if (this.$numFormat(val.data)) {
                  str += `<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`;
                 
                }
              });
              return str;
            };
            return opt;
          } else {
            return null;
          }
        },
   }
})
</script>
<style lang="scss">
.factormangedialog{
    .el-dialog__title{
        font-size: 16px;
    }
    .el-dialog__body{padding-top: 0;}
}
.noheadercustom-dialog .el-dialog__header{
        padding: 0;
    }
</style>
