<template>
    <div class="flex-column" :style="{'width':width}">
         <div class="legend" v-if="customLegend">
            <div class="item" v-for="(item,index) in legendData.details" :key="index">
                <div>
                    <span class="marker" :style="{'background':item.color}"></span>
                    <span>{{item.name.length>12?item.name.substring(0,6)+"..."+item.name.substring(item.name.length-6,item.name.length):item.name}}</span>
                </div>
                <div><span class="num" v-html="item.value"></span><span v-html="item.unit"></span></div>
            </div>
        </div>
        <div v-show="option" ref="chart" class="flex-auto" :style="{'height':height}"></div>
        <div v-show="!option" class="flex-auto flex-column justify-center align-items-center" :style="{'height':height}">
            <!-- <img src="@/assets/images/chart-icon.png" height="50%" style="max-height:150px" /> -->
            <el-empty :image-size="80" :description="emptyText"></el-empty>
        </div>
    </div>
   
</template>
<script>
export default {
    name: 'Chart',
    props:{
        option:{
            type:Object
        },
        height:{
            type:String,
            default:'300px'
        },
        width:{
            type:String,
            default:'auto'
        },
        backgroundLight:{//图表背景色（浅色模式）
            type:String,
            default:'#fff'
        },
        backgroundDark:{//图表背景色（深色模式）
            type:String,
            default:'#1b202c'
        },
        customLegend:{
            type:Boolean,
            default:false
        },
        legendAutoHeight:{
            type:Boolean,
            default:true
        },
        emptyText:{
            type:String,
            default:""
        }
    },
    beforeCreate(){
        
    },
    data(){
        return {
            chartsInstance:null,
            timer:null,
            legendData:{
                index:0,
                details:[
                   // {color:"",name:"",value:"",unit:""}
                ] 
            },
            legendTextArr:[]
        }
    },
    mounted(){
        this.drawChart(this.option,this.$store.state.theme)
        //监听尺寸变化
        this.sizeLinstener()
        //鼠标悬浮显示自定义图例数据
        this.highlightLinstener()
    },
    beforeDestroy(){
        this.$erd.uninstall(this.$refs.chart)
        this.chartsInstance.dispose()
    },
    methods: {
        drawChart (option,theme) {
            // 基于准备好的dom，初始化echarts实例
            !this.chartsInstance && (this.chartsInstance = this.$echarts.init(this.$refs.chart,theme));
            // 绘制图表
            this.chartsInstance.setOption(option||{},true);
            this.chartsInstance.setOption({backgroundColor: this.$store.state.theme=='light'?this.backgroundLight:this.backgroundDark});
            this.adaptLegend()//适应图例高度
            
            //自定义图例初始值显示
            if(this.customLegend&&this.option&&this.option.legend.data.length&&this.option.legend.data.findIndex((value)=>{return value.custom})>=0&&this.option.legend.data.findIndex((value)=>{return !value.custom.invisible})>=0){
                //图例去重
                this.legendTextArr=[]
                this.option.legend.data.forEach(val=>{
                    if(!this.legendTextArr.some(v=>{return v==val.name})){
                        this.legendTextArr.push(val.name)
                    }
                })
                let legend=[]
                this.option.series.forEach((val,index) => {
                    if(this.option.legend.data[index].custom&&!this.option.legend.data[index].custom.invisible&&this.$numFormat(val.data[val.data.length-1])){
                        if(!legend.some(v=>{return v.name==val.name})){
                            legend.push( {
                                color:this.option.legend.data[index].custom.color||this.option.color[this.legendTextArr.findIndex(vv=>{return vv==val.name})%this.option.color.length],
                                name:val.name,
                                value:this.option.legend.data[index].custom.formatter?option.legend.data[index].custom.formatter(val.data[val.data.length-1],index,val.data.length-1):val.data[val.data.length-1],
                                unit:this.option.legend.data[index].custom.unit||"",
                            })
                        }
                    }
                });
                this.legendData.index = this.option.series[this.option.legend.data.findIndex((value)=>{return !value.custom.invisible})].data.length-1
                this.legendData.details = legend
            }else{
                this.legendData={
                    index:0,
                    details:[
                    // {color:"",name:"",value:"",unit:""}
                    ] 
                }
            }
        }, 
        //鼠标悬浮显示相应自定义图例数据
        highlightLinstener(){
            this.chartsInstance.on( "highlight", res =>{
                if(this.customLegend&&this.option&&this.option.legend.data.length&&this.option.legend.data.findIndex((value)=>{return value.custom})>=0&&this.option.legend.data.findIndex((value)=>{return !value.custom.invisible})>=0&&res.escapeConnect){
                    let legend=[]
                    res.batch.forEach(val => {
                        if(this.option.legend.data[val.seriesIndex].custom&&!this.option.legend.data[val.seriesIndex].custom.invisible&&this.$numFormat(this.option.series[val.seriesIndex].data[val.dataIndex])){
                            if(!legend.some(v=>{return v.name==this.option.legend.data[val.seriesIndex].name})){
                                legend.push( {
                                    color:this.option.legend.data[val.seriesIndex].custom.color||this.option.color[this.legendTextArr.findIndex(vv=>{return vv==this.option.legend.data[val.seriesIndex].name})%this.option.color.length],
                                    name:this.option.legend.data[val.seriesIndex].name,
                                    value:this.option.legend.data[val.seriesIndex].custom.formatter?this.option.legend.data[val.seriesIndex].custom.formatter(this.option.series[val.seriesIndex].data[val.dataIndex],val.seriesIndex,val.dataIndex):this.option.series[val.seriesIndex].data[val.dataIndex],
                                    unit:this.option.legend.data[val.seriesIndex].custom.unit,
                                })
                            }
                            
                        }
                    });
                    let index = this.option.legend.data.findIndex((value)=>{return !value.custom.invisible})
                    this.legendData.index = res.batch[index]?res.batch[index].dataIndex:res.batch[0].dataIndex
                    this.legendData.details = legend
                }
            })
        },
        //图表尺寸变化监听
        sizeLinstener(){
            this.$erd.listenTo({
                strategy: "scroll", //<- For ultra performance.
                callOnAdd: false,
                debug: false
            },this.$refs.chart, ()=>{
                if(this.chartsInstance){
                    this.chartsInstance.resize({animation:{duration:1000}})
                    this.adaptLegend()
                }
            });
        },
        //适应图例高度
        adaptLegend(){
            if(this.option&&this.legendAutoHeight){
                clearTimeout(this.timer)
                this.timer=setTimeout(()=>{
                    if(this.option.dataZoom&&this.option.dataZoom.show){
                        this.chartsInstance.setOption({
                            grid:{bottom:this.getLegendHeight()+15},
                            dataZoom:{bottom:this.getLegendHeight()}
                        });
                    }else{
                        this.chartsInstance.setOption({grid:{bottom:this.getLegendHeight()}});
                    }   
                },0)
            }
        },
        //图例高度计算
        getLegendHeight(index){
			var height =0;
			var charDOM = this.$refs.chart;
			var chart = this.chartsInstance;
			var option = chart.getOption();
			var legends = option.legend;
			if(!legends||legends.length<=0) return 0;
			index = parseInt(index);
			if(isNaN(index)||index<0||index>=legend.length) index = 0;
			var legend = legends[index];
			if(!legend||!legend.show||!legend.data||legend.data.length<=0) return 0;
			//主算法，将legend中的设置渲染为DOM元素，用dom元素的宽高来模拟legend组件在echarts内部的高度
			var icongap = 5;//legend图形有5个px的间隔
			var left =isNaN(legend.left)?0:legend.left ,right = isNaN(legend.right)?0:legend.right;
			//计算legend组件的可用宽度
			var chartWidth = legend.width||charDOM.clientWidth-left-right;
			//legend的padding
			var padding = legend.padding || 0;
			if(Array.isArray(padding)) padding = padding.join('px ')+'px';
			else padding+='px';
			//每个legend item之间的间隙（包括水平和垂直）
			var itemGap = legend.itemGap;
			//创建一个不可见的模拟盒子
			var $legendbox = document.createElement('div')
                $legendbox.style.width=(chartWidth+itemGap) +'px'
                $legendbox.style.background='gray'
                $legendbox.style.lineHeight='1'
                $legendbox.style.padding=padding
                $legendbox.style.boxSizing='border-box'
                $legendbox.style.overflow='hidden'
                $legendbox.style.position='absolute'
                $legendbox.style.top=0
                $legendbox.style.zIndex=-1 
                $legendbox.style.opacity='0'
                $legendbox.style.filter='alpha(opacity=0)'
                $legendbox.style.msFilter='alpha(opacity=0)'
            document.body.appendChild($legendbox);
            
			//模拟绘制单个legend item
			var itemHeight = legend.itemHeight,itemWidth =legend.itemWidth;
			if(itemHeight%2!=0) itemHeight++;
			if(itemWidth%2!=0) itemWidth++;
			var fontSize = legend.textStyle.fontSize || 12;
			var fontWeight = legend.textStyle.fontWeight || 'normal';
            let names=legend.data.map(v=>{return v.name||v})
            new Set(names).forEach(function(v){
                var $icon = document.createElement('span')
                $icon.style.float='left'
                $icon.style.marginRight=icongap+'px'
                $icon.style.boxSizing='content-box'
                $icon.style.width=(itemWidth+1)+'px'
                $icon.style.height=itemHeight+'px'
                $icon.style.background='red'

                var $item = document.createElement('span')
                $item.style.float='left'
                $item.style.marginRight=itemGap+'px'
                $item.style.marginBottom=itemGap+'px'
                $item.style.fontSize=fontSize+'px'
                $item.style.fontWeight=fontWeight


                $item.innerText=(legend.formatter&&legend.formatter(v))||v
                $legendbox.appendChild($item)
                $item.appendChild($icon)

                
            })
			//得到模拟高度
			height = $legendbox.clientHeight;
			//善后工作
			try {
                $legendbox.remove();
            } catch (error) {
                $legendbox.removeNode(true);
            }
            if(height > parseInt(this.$refs.chart.clientHeight/2)){
                this.$refs.chart.style.height=this.$refs.chart.clientHeight+parseInt(height/2)+'px'
            }
            else if(height<parseInt((this.$refs.chart.clientHeight-height)/2)){
                this.$refs.chart.style.height=this.height
            } 
            
			return height;
		}
    },
    watch:{
        'option':{
            handler:function(newval){
               this.drawChart(newval,this.$store.state.theme)
            },
            deep:true
        },
        '$store.state.theme'(){
          this.$erd.uninstall(this.$refs.chart)
          this.chartsInstance.dispose()
          this.chartsInstance = null
          this.drawChart(this.option,this.$store.state.theme)
          this.sizeLinstener()
          this.highlightLinstener()
        },
    }
}
</script>
<style lang="scss" scoped>
/* 自定义图例------------------------------------------------- */
.legend{
    display: flex;
    flex-wrap: wrap;
    @include themeify{color:themed('textColor');};
    height: 65px;
    overflow: hidden;
}
.legend .item{
    margin-right: 5%;
    margin-top: 10px;
}
.legend .item>div:first-child{
    margin-bottom: 5px;
}
.legend .item .marker{
    display: inline-block;
    vertical-align: middle;
    width: 3px;
    background: #5470c6;
    height: 12px;
    margin-right: 5px;
}
.legend .item .num{
    font-size: 24px;
    font-weight: bold;
}
</style>


